.create-user-div {
  background-color: var(--color-solid-lightgrey);
  border-radius: 10px;
}

.create-user-entity-div {
  background-color: var(--color-solid-white);
  border-radius: 10px;
  border: 1px solid var(--color-solid-darkgrey);
  padding: 0px 0px 30px 0px;
  margin-top: 20px;
  position: relative;
}

/* .create-roles-div {
  background-color: var(--color-solid-backgroundblue);
  padding: 20px;
  margin: 40px 20px 10px 20px;
  border-radius: 13px;
} */

.create-pageaccess-div {
  background-color: var(--color-solid-white);
  color: var(--color-solid-darkergrey);
  border: 1px solid var(--color-solid-darkgrey);
  height: 30px;
  margin: 40px 20px 10px 20px;
  border-radius: 8px;
  padding: 4px 0px 0px 10px;
}

.create-pageaccess {
  background-color: var(--color-solid-white);
  color: var(--color-solid-darkergrey);
  border: 1px solid var(--color-solid-darkgrey);
  height: 30px;
  margin: 6px 20px 10px 20px;
  border-radius: 8px;
  padding: 4px 0px 0px 10px;
}

#role-create-table .ant-table-tbody .ant-table-cell {
  background-color: var(--color-solid-backgroundblue) !important;
  padding: 12px 14px 0px 14px !important;
}

#role-create-table .ant-table-thead > tr > th {
  background-color: var(--color-solid-backgroundblue) !important;
}

#role-create-table .ant-form-item {
  margin-bottom: 12px !important;
}

.access-title {
  text-align: center;
  background-color: #fff;
  padding: 5px 50px;
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  position: absolute;
  top: -16px;
  left: 4rem;
  color: var(--color-solid-darkergrey);
  font-weight: 600;
  font-size: 13px;
}

.add-data-col {
  background-color: var(--color-solid-backgroundblue) !important;
  padding: 4px 14px 0 50px !important;
  border-radius: 12px;
}

.added-div {
  padding: 0 20px;
}

.added-data-col {
  background-color: var(--color-solid-backgroundblue) !important;
  padding: 15px 50px !important;
  border-radius: 12px;
}

.ellipsis-input {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 6px 20px;
}

.added-select {
  width: 100%;
}

.added-select .ant-select-selector {
  padding: 4px 20px !important;
}

.role-entity-list {
  background-color: white !important;
  padding: 0px 15px !important;
}

.role-entity-list .ant-list-item {
  background-color: white !important;
  padding: 9px 0px !important;
}

.role-entity-list .ant-list-item > label {
  color: var(--color-solid-darkergrey) !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}
