/********************************************************************
- Global Button CSS Template:
* If name of the button has multiple words, split it with hypens 
  while naming the button.
* Adjust padding-left or padding right parameter (percentage values
  for responsiveness) to center the button title on the button.
* For a normal button the default height is set to 35px.
  For buttons that need to be slightly taller (like Create Button on main pages), set height to 38px.

  
#buttonName-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 15% !important;
  border-radius: 20px !important;
  height: 35px !important;
  width: 100%;
}


*********************************************************************/

/* .ant-btn-primary:disabled {
  cursor: default !important;
} */

/* reduced the border radius 20px to 8px for all buttons */
#create-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* padding-right: 20% !important; */
  /* border-radius: 20px !important; */
  border-radius: 8px !important;
  height: 38px !important;
  width: 100%;
}

#create-button-short-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 15% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#cancel-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 20% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#save-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 15% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#submit-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 15% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#ok-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 20% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#delete-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 10% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#next-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 15% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#add-user-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 20% !important;
  border-radius: 8px !important;
  height: 38px !important;
  width: 100%;
}

#add-user-short-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 20% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#excel-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 15% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#table-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 15% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#approve-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 15% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#return-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 15% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#reject-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 15% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#clear-all-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 20% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#upload-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 10% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#online-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 20% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#download-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 20% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#download-template-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 10% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#add-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 20% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#view-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 20% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#generate-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 13% !important;
  border-radius: 8px !important;
  height: 38px !important;
  width: 100%;
}

#schedule-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 10% !important;
  border-radius: 8px !important;
  height: 38px !important;
  width: 100%;
}

#browse-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 15% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#batch-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 15% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#latest-contract-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 10% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#preview-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 15% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#create-version-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#upload-document-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#compare-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 10% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#author-change-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 10% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}

#pre-signed-button-global {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 10% !important;
  border-radius: 8px !important;
  height: 35px !important;
  width: 100%;
}
