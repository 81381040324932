.floating-buttons {
  position: fixed;
  bottom: 20px; /* Adjust as needed */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust for centering */
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between buttons */
  z-index: 1000; /* Ensure buttons are above other content */
}

.floating-buttons .ant-btn {
  border-radius: 50px; /* Make buttons rounded */
  padding: 10px 20px; /* Adjust padding */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  width: 150px; /* Set a fixed width for uniformity */
}

/* main tabs starts */

.contract-tree-view
  .org-supplier-label
  .ant-form-item-label
  .ant-form-item-no-colon {
  font-size: 13px;
  color: var(--color-solid-darkgrey);
}

.pipeline-view-div .ant-tabs-nav,
.contract-view-div #contree-tab .ant-tabs-nav {
  background-color: var(--color-solid-backgroundgrey);
  margin: 0;
}

.pipeline-view-div .ant-tabs-nav-wrap,
.contract-view-div #contree-tab .ant-tabs-nav-wrap {
  /* padding-left: 20px; */
  background-color: var(--color-solid-backgroundgrey);
}

.pipeline-view-div .ant-tabs-nav .ant-tabs-tab,
.contract-view-div #contree-tab .ant-tabs-nav .ant-tabs-tab {
  background-color: var(--color-solid-backgroundgrey);
  border: none;
  transition: none;
  margin: 0;
  padding: 8px 16px;
}

.pipeline-view-div .ant-tabs .ant-tabs-tab-btn .tabs-ss-title,
.contract-view-div .ant-tabs .ant-tabs-tab-btn .tabs-ss-title {
  color: var(--color-solid-darkergrey);
  text-shadow: 0 0 1px var(--color-solid-darkgrey);
}

.pipeline-view-div
  .ant-tabs
  .ant-tabs-tab-active
  .ant-tabs-tab-btn
  .tabs-ss-title,
.contract-view-div
  .ant-tabs
  .ant-tabs-tab-active
  .ant-tabs-tab-btn
  .tabs-ss-title {
  font-weight: 600;
  color: var(--color-solid-darkblue);
  text-shadow: none;
}

.pipeline-view-div .ant-tabs .ant-tabs-tab-active,
.contract-view-div #contree-tab .ant-tabs .ant-tabs-tab-active {
  background-color: var(--color-solid-white) !important;
  border: none;
}

.pipeline-view-div .ant-tabs-nav .ant-tabs-tab-active,
.contract-view-div #contree-tab .ant-tabs-nav .ant-tabs-tab-active {
  background: var(--color-solid-white);
  border: none;
  transition: none;
  padding: 8px 16px;
  border-radius: 8px 8px 0 0;
}

.pipeline-view-div .ant-tabs-nav .ant-tabs-tab-active:before,
.pipeline-view-div .ant-tabs-nav .ant-tabs-tab-active:after,
.contract-view-div #contree-tab .ant-tabs-nav .ant-tabs-tab-active:before,
.contract-view-div #contree-tab .ant-tabs-nav .ant-tabs-tab-active:after {
  content: "";
  position: absolute;
  height: 10px;
  width: 20px;
  bottom: 0;
}

.pipeline-view-div .ant-tabs-nav .ant-tabs-tab-active:after,
.contract-view-div #contree-tab .ant-tabs-nav .ant-tabs-tab-active:after {
  right: -20px;
  border-radius: 0 0 0 5px;
  box-shadow: -10px 0 0 0 var(--color-solid-white);
}

.pipeline-view-div .ant-tabs-nav .ant-tabs-tab-active:before,
.contract-view-div #contree-tab .ant-tabs-nav .ant-tabs-tab-active:before {
  left: -20px;
  border-radius: 0 0 5px 0;
  box-shadow: 10px 0 0 0 var(--color-solid-white);
}

.pipeline-view-div .ant-tabs-nav .ant-tabs-tab-disabled,
.contract-view-div #contree-tab .ant-tabs-nav .ant-tabs-tab-disabled {
  cursor: default !important;
  padding: 0;
  margin-left: 3px;
}

.pipeline-view-div .tab-divider,
.contract-view-div #contree-tab .tab-divider {
  height: 17px;
  border: 1px solid #b0afb2;
}

.pipeline-view-div .ant-tabs-ink-bar,
.contract-view-div #contree-tab .ant-tabs-ink-bar {
  display: none !important;
}

/* .contract-tree-view .ant-tabs-nav-wrap {
  background-color: var(--color-solid-backgroundgrey);
}

.contract-tree-view .ant-tabs-nav .ant-tabs-tab-active {
  margin: 0 !important;
}

.contract-tree-view .ant-tabs-nav .ant-tabs-tab-active::before {
  display: none;
}

.contract-tree-view .ant-tabs-nav .ant-tabs-tab-active::after {
  display: none;
}

.pipeline-tree-view .ant-tabs-nav {
  margin-bottom: 6px;
}

.pipeline-tree-view .ant-tabs-extra-content {
  flex: auto !important;
}

.pipeline-tree-view .ant-tabs-nav-wrap-ping-right::after {
  opacity: 0 !important;
}

.contract-view-div .ant-tabs-tab-active .ant-tabs-tab-btn,
.pipeline-view-div .ant-tabs-tab-active .ant-tabs-tab-btn {
  border: none !important;
  border-radius: 24px;
  padding: 10px 25px !important;
  background-color: var(--color-solid-darkblue) !important;
  color: var(--color-solid-white) !important;
  text-align: center;
}

.contract-view-div .ant-tabs-tab .ant-tabs-tab-btn,
.pipeline-view-div .ant-tabs-tab .ant-tabs-tab-btn {
  border: none !important;
  padding: 10px 25px !important;
  text-align: center;
  color: var(--color-solid-darkergrey);
}

.contract-view-div .ant-tabs-nav::before,
.pipeline-view-div .ant-tabs-nav::before {
  border-bottom: none !important;
}

.contract-view-div .ant-tabs-nav-wrap .ant-tabs-nav-list,
.pipeline-view-div .ant-tabs-nav-wrap .ant-tabs-nav-list {
  border: none;
  background: linear-gradient(
    164.9deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(246, 246, 246, 0.5) 100%
  );
  width: 100%;
  border-radius: 24px;
}

.contract-view-div .ant-tabs-tab,
.pipeline-view-div .ant-tabs-tab {
  padding: 0 !important;
  background: none !important;
  margin: 0 !important;
}

.contract-view-div .ant-tabs-ink-bar,
.pipeline-view-div .ant-tabs-ink-bar {
  background-color: transparent !important;
}

.contract-view-div .ant-tabs-tab-btn,
.pipeline-view-div .ant-tabs-tab-btn {
  width: 180px;
  margin-right: 30px;
} */

.contract-view-div .contract-breadcrumb,
.pipeline-view-div .contract-breadcrumb {
  display: block !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.contract-view-div .ant-menu,
.pipeline-view-div .ant-menu {
  height: calc(100vh - 219px) !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.pipeline-org-view .ant-menu {
  height: calc(100vh - 182px) !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.contract-view-div .ant-menu-vertical,
.pipeline-view-div .ant-menu-vertical {
  border-inline-end: 2px solid var(--color-solid-lightgrey) !important;
}

.contract-view-div .ant-menu-item,
.pipeline-view-div .ant-menu-item {
  color: var(--color-solid-darkergrey) !important;
}

.contract-view-div .ant-menu-item-only-child,
.pipeline-view-div .ant-menu-item-only-child {
  /* margin-left: 38px !important; */
  padding: 0 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  margin-inline: 20px !important;
  margin-block: 4px !important;
  padding-inline: 12px !important;
  transition: none !important;
}

.contract-view-div .ant-menu-light .ant-menu-item-selected,
.pipeline-view-div .ant-menu-light .ant-menu-item-selected {
  background-color: transparent !important;
  color: var(--color-solid-darkblue) !important;
  font-weight: 600;
  padding: 0 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  margin-inline: 0px !important;
  margin-block: 4px !important;
  padding-inline: 0px !important;
  transition: none !important;
}

.contract-view-div .ant-menu-item:hover,
.pipeline-view-div .ant-menu-item:hover {
  background-color: transparent !important;
}

.contract-view-div {
  background-color: var(--color-solid-backgroundgrey);
  height: calc(100vh - 98px);
  overflow-x: hidden;
}

.pipeline-view-div {
  background-color: var(--color-solid-backgroundgrey);
  height: calc(100vh - 65px);
  overflow-x: hidden;
}

.contract-view-div .org-view-div {
  background-color: var(--color-solid-white);
  border-radius: 0 20px 20px 20px;
  /* margin-right: 5px; */
  padding: 5px;
  height: calc(100vh - 189px);
  overflow-y: auto;
  overflow-x: hidden;
}

.pipeline-view-div .pipeline-org-view {
  background-color: var(--color-solid-white);
  border-radius: 0 20px 20px 20px;
  /* margin-right: 5px; */
  padding: 5px;
  height: calc(100vh - 152px);
  overflow: auto;
}

.contract-view-div .contract-info-view {
  padding: 5px 0px 5px 5px;
  margin-left: 20px;
  height: calc(100vh - 219px);
  overflow: auto;
}

.pipeline-view-div #view-contract-screen {
  padding: 5px 0px 5px 5px;
  margin-left: 20px;
  height: calc(100vh - 182px);
  overflow: auto;
}

#flowchart-card {
  border: none !important;
}

#flowchart-card .ant-card-body {
  padding: 0 !important;
}

.pipeline-documents-div {
  border: 1px solid var(--color-border);
  border-radius: 15px;
  /* padding: 5px; */
}

.contract-view-div .documents-div .notes-div,
.pipeline-view-div .pipeline-documents-div .notes-div {
  display: flex;
  align-items: center;
  border-radius: 10px;
  position: absolute;
  bottom: 8px;
  left: 7px;
  /* background-color: #f1f1f1;
  color: var(--color-solid-darkgrey);
  font-weight: 600;
  font-size: 13px;
  padding: 10px 20px 10px 20px;
  border-radius: 15px; */
  min-width: 99%;
}

.contract-view-div .documents-div .notes-icons,
.pipeline-view-div .pipeline-documents-div .notes-icons {
  display: flex;
  margin-left: auto;
}

.contract-view-div .org-view-div .document-item,
.pipeline-view-div .pipeline-org-view .document-item {
  border-bottom: var(--color-solid-lightgrey) solid 2px;
}

.contract-view-div .org-view-div .document-item-align,
.pipeline-view-div .pipeline-org-view .document-item-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

#contract-header-text {
  color: var(--color-solid-darkgrey);
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* .contract-file-tab .main-tab .ant-tabs-nav-wrap {
  justify-content: center;
}

.contract-file-tab .main-tab .ant-tabs-tab-active {
  border-bottom: 2px solid var(--color-solid-black) !important;
}

.contract-file-tab .main-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
  padding: 10px 15px !important;
  background-color: transparent !important;
  color: var(--color-solid-black) !important;
  text-align: center;
}

.contract-file-tab .main-tab .ant-tabs-tab .ant-tabs-tab-btn {
  border: none !important;
  padding: 10px 25px !important;
  text-align: center;
}

.contract-file-tab .main-tab .ant-tabs-tab .ant-tabs-tab-btn:hover {
  color: var(--color-solid-black) !important;
}

.contract-file-tab .main-tab .ant-tabs-nav::before {
  border-bottom: none !important;
}

.contract-file-tab .main-tab .ant-tabs-tab {
  padding: 0 !important;
}

.contract-file-tab .main-tab {
  border-bottom: 1px solid var(--color-lightshade-grey);
}

.contract-file-tab .main-tab .ant-tabs-top > .ant-tabs-nav {
  margin: 0 !important;
}

.contract-file-tab .main-tab .ant-tabs-ink-bar {
  background-color: transparent !important;
}

.contract-file-tab .main-tab .ant-tabs-tab .title {
  display: inline-flex;
} */

/* main tabs ends */

/* steps starts */

.contract-file .white-card {
  border-radius: 15px;
  margin: 0;
  padding: 10px 20px !important;
  /* border: 1px solid var(--color-border); */
  background-color: var(--color-solid-white);
  color: var(--color-solid-black);
}

.contract-file .file-title {
  font-weight: 600;
  font-size: 14px;
  padding: 10px;
}

.contract-file
  .ant-steps-item
  .ant-steps-item-icon:hover
  .ant-steps-item-content {
  display: block !important;
}

.contract-file .ant-steps-item .ant-steps-item-content {
  display: none !important;
}

.contract-file
  .ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot,
.contract-file
  .ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: var(--color-solid-green) !important;
}

/* steps ends */

.contract-file .inner-tab-div {
  padding: 10px;
  border: 1px solid var(--color-lightshade-grey);
  border-radius: 15px;
  margin: 15px 0;
}

/* inner tabs starts */

/* .contract-file-tab .inner-tab .ant-tabs-nav-wrap {
  justify-content: center;
}

.contract-file-tab .inner-tab .ant-tabs-tab-active {
  border-bottom: 0 !important;
}

.contract-file-tab .inner-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
  padding: 10px 15px !important;
  background-color: transparent !important;
  color: var(--color-solid-darkblue) !important;
  text-align: center;
}

.contract-file-tab .inner-tab .ant-tabs-tab .ant-tabs-tab-btn {
  border: none !important;
  padding: 10px 25px !important;
  text-align: center;
}

.contract-file-tab .inner-tab .ant-tabs-tab .ant-tabs-tab-btn:hover {
  color: var(--color-solid-darkblue) !important;
}

.contract-file-tab .inner-tab .ant-tabs-nav::before {
  border-bottom: none !important;
}

.contract-file-tab .inner-tab .ant-tabs-tab {
  padding: 0 !important;
}

.contract-file-tab .inner-tab {
  border-bottom: 1px solid var(--color-lightshade-grey);
}

.contract-file-tab .inner-tab .ant-tabs-top > .ant-tabs-nav {
  margin: 0 !important;
}

.contract-file-tab .inner-tab .ant-tabs-ink-bar {
  background-color: transparent !important;
}

.contract-file-tab .inner-tab .ant-tabs-tab .title {
  display: inline-flex;
} */

/* inner tabs ends */
.contract-file .inner-tab-content {
  /* max-height: 27vh; */
  overflow: scroll;
}

/* Form starts */
.contract-file .ant-form {
  padding: 15px;
}

.contract-file .ant-form .ant-form-item {
  width: 100%;
  margin: 10px 0;
}

.contract-file .ant-form .ant-form-item-row {
  width: 100%;
}

.contract-file .ant-form .ant-form-item-label {
  width: 30%;
  white-space: initial;
}

.contract-file .ant-form .ant-form-item-label label {
  height: auto;
}

.contract-file .ant-form .ant-form-item-control {
  width: 70%;
}
/* Form ends */

/* PDF Starts */
.contract_file_min .react-pdf__Page {
  max-width: 100% !important;
  min-width: 100% !important;
  min-height: 49vh !important;
}

.contract_file_min .textLayer,
.contract_file_min .annotationLayer,
.contract_file_min canvas {
  height: 49vh !important;
  width: 100% !important;
  overflow-x: scroll;
}

.contract_file_max .react-pdf__Page {
  max-width: 100% !important;
  min-width: 100% !important;
  min-height: 65vh !important;
}

.contract_file_max .textLayer,
.contract_file_max .annotationLayer,
.contract_file_max canvas {
  height: 65vh !important;
  width: 100% !important;
  overflow-x: scroll;
}

.contract-file .textLayer,
.contract-file .annotationLayer {
  display: none;
}

.contract-file .pdf-card p {
  text-align: center;
  font-size: 10px;
}

.contract-file .pdf-card button {
  padding: 3px 10px;
  font-size: 8px;
  border: 0;
}

.contract-file .pdf-card button:last-child {
  float: right;
}

/* PDF Ends */
.reject-btn,
.reject-btn:hover {
  height: 40px;
  box-shadow: none !important;
  background: transparent !important;
  border: 1px solid var(--color-solid-red);
  border-radius: 20px;
  color: var(--color-solid-red) !important;
  font-weight: 600;
}

.approve-btn,
.approve-btn:hover {
  height: 40px;
  box-shadow: none !important;
  background: var(--color-solid-darkblue) !important;
  border: 1px solid var(--color-solid-darkblue);
  border-radius: 20px;
  color: var(--color-solid-white) !important;
  font-weight: 600;
}

.submit-btn,
.submit-btn:hover {
  height: 35px !important;
  box-shadow: none !important;
  background: var(--color-solid-darkblue) !important;
  border: 1px solid var(--color-solid-darkblue) !important;
  border-radius: 13px;
  color: var(--color-solid-white) !important;
  font-weight: 600;
  width: 100px;
}

.return-btn,
.return-btn:hover {
  height: 40px;
  box-shadow: none !important;
  background: transparent !important;
  border: 1px solid var(--color-solid-orange);
  border-radius: 20px;
  color: var(--color-solid-orange) !important;
  font-weight: 600;
}

.contract-comments-div {
  /* height: 12rem; */
  min-height: 190px;
  overflow: scroll;
  margin: 10px 0;
  padding: 0 20%;
  display: flex;
  flex-direction: column-reverse;
}

.contract-comments-align-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.contract-comments-align-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contract-comments-new {
  width: fit-content;
  border: 2px solid var(--color-solid-darkerblue);
  border-radius: 10px;
  padding: 10px 13px;
  max-width: 40rem;
  min-width: 10rem;
}

.contract-comments-old {
  width: fit-content;
  /* border: 2px solid var(--color-solid-darkergrey); */
  color: var(--color-solid-white);
  border-radius: 10px;
  padding: 10px 10px 6px 10px;
  max-width: 40rem;
  min-width: 10rem;
  /* background-color: rgb(70, 193, 70); */
  background-color: var(--color-solid-darkerblue);
}

.comments-divider {
  margin: 5px 0px;
  background-color: var(--color-solid-white);
}

.contract-notes-div {
  background-color: var(--color-solid-white);
  padding: 5px;
  /* height: 300px;  */
  border-radius: 10px; /* Adjust the border-radius to control the roundness of the corners */
  border: 1px solid var(--color-solid-lightgrey);
}

.contract-notes-input {
  background-color: #f1f1f1;
  padding: 10px 20px 10px 20px;
  border-radius: 15px;
}

.contract-notes-input .ant-input {
  background: transparent;
}

.contract-notes-icons {
  display: flex;
  margin-left: auto;
}

.contract-author-data {
  font-size: 12px;
  color: var(--color-solid-darkergrey);
  padding-bottom: 5px;
}

.com-table-data-div {
  padding: 5px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 13px;
  width: 100%;
  font-weight: 100;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#msControlMenu2,
#msControlMenu3 {
  display: none !important;
}

.eKdVKZ {
  background-image: none !important;
}

.contract-arrow > svg {
  width: 45px;
  height: 35px;
  color: var(--color-solid-darkgrey);
}

.move-up-btn,
.move-down-btn {
  padding: 0px !important;
  width: 30px !important;
  height: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-solid-white);
  color: var(--color-solid-darkergrey);
  border: 1px solid var(--color-solid-darkergrey);
  box-shadow: none;
}

.move-up-btn:not([disabled]):hover,
.move-down-btn:not([disabled]):hover {
  background-color: var(--color-solid-darkerblue) !important;
  color: var(--color-solid-white) !important;
  border: 1px solid var(--color-solid-darkerblue) !important;
}

.added-contract-text {
  background-color: #c6e0b4;
  color: var(--color-solid-black);
  margin: 0px 2px;
  padding: 2px 3px;
  border-radius: 5px;
}

.removed-contract-text {
  background-color: #ffe699;
  color: var(--color-solid-black);
  margin: 0px 2px;
  padding: 2px 3px;
  border-radius: 5px;
  text-decoration: line-through;
}

.unchanged-contract-text {
  background-color: transparent;
}

.highlight-contract-text {
  border: 2px solid var(--color-solid-darkergrey);
  margin: 5px;
  padding: 2px 5px;
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.3);
}

.file-viewer-wrapper .document-container {
  /* width: calc(100vw - 600px) !important; */
  height: calc(100vh - 220px);
}

.floating-buttons1 {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 8px;
}

.floating-buttons1 .ant-btn {
  background-color: #f0f0f0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contract-reactcomments-div {
  overflow: scroll;
  margin: 10px 0;
  padding: 0 20%;
  display: flex;
  flex-direction: column-reverse;
}

.reactquill-container {
  /* background-color: var(--color-solid-backgroundgrey);
  border-radius: 17px; */
  position: relative;
}

.reactquill-container .quill {
  border: 1px solid #0092ff;
  border-radius: 17px;
  height: 58px;
}

.reactquill-container .floating-buttons {
  display: flex;
  align-items: center;
}

.reactquill .quill {
  border: 1px solid #0092ff;
  height: fit-content;
  width: fit-content;
}

.centered-quill .ql-editor {
  display: flex;
  align-items: center;
}
